import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/",
  //   name: "prizeCheck",
  //   component: () => import("@/components/prizeCheck.vue"),
  // },

  {
    path: "/",
    name: "buyLottery",
    component: () => import("@/components/buyLottery.vue"),
  },

  {
    path: "/register",
    name: "register",
    component: () => import("@/components/register.vue"),
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("@/components/profile.vue"),
  },
  {
    path: "/basket",
    name: "basket",
    component: () => import("@/components/basket.vue"),
  },
  {
    path: "/payment",
    name: "payment",
    component: () => import("@/components/payment.vue"),
  },
  {
    path: "/paymentConfirm",
    name: "paymentConfirm",
    component: () => import("@/components/paymentConfirm.vue"),
  },
  {
    path: "/safe",
    name: "safe",
    component: () => import("@/components/safe.vue"),
  },
  {
    path: "/order",
    name: "order",
    component: () => import("@/components/order.vue"),
  },
  {
    path: "/transactionDetail",
    name: "transactionDetail",
    component: () => import("@/components/transactionDetail.vue"),
  },
  {
    path: "/lineLogin",
    name: "lineLogin",
    component: () => import("@/components/lineLogin.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  console.log(to, from);
  if (from.path === "/payment") {
    localStorage.removeItem("paymentItems");
    localStorage.removeItem("storedDatetime");
  }
  next();
});

export default router;
